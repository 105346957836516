//ElementPlusResolver 与最新 ElementPlus 不兼容的临时处理

import 'element-plus/theme-chalk/el-button.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-form.css'
import 'element-plus/theme-chalk/el-form-item.css'
import 'element-plus/theme-chalk/el-input.css'
import 'element-plus/theme-chalk/el-checkbox.css'

import {App} from "vue";

export default (app: App) => {}