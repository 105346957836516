import {ActionContext, Module, Store} from "vuex";
import {AppState} from "@/store";
import {routerStore} from "@/router";

export interface UserInfoState {
    isLogined: boolean,
    nickname: string
}

const userInfo = {
    namespaced: true,
    state: {
        isLogined: false,
        nickname: ''
    },
    getters: {},
    mutations: {
        changeLoginState(state, payload) {
            state.isLogined = payload
            routerStore.isLogined = payload
        }
    },
    actions: {
        login(context, { username, password}) {

        }
    }
} as Module<UserInfoState, AppState>

export default userInfo