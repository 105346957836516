import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineAsyncComponent } from "vue";
var Content = defineAsyncComponent(function () {
  return import('@/Content.vue');
});
export default {
  components: {
    Content: Content
  }
};