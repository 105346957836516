import {createStore, useStore as baseUseStore, Store} from 'vuex'
import userInfo from "@/store/modules/UserInfo";
import {InjectionKey} from "vue";

export interface AppState{
    version: string
}

export const appStateKey: InjectionKey<Store<AppState>> = Symbol()

export function useStore(): Store<AppState> {
    return baseUseStore(appStateKey)
}

export const store = createStore<AppState>({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        version: ''
    },
    mutations: {},
    actions: {},
    modules: {
        userInfo
    }
})
