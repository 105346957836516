import '@/assets/reset.css'
import 'animate.css'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import {appStateKey, store} from '@/store'
import installElementPlus from '@/plugins/element'

const app = createApp(App)
installElementPlus(app)
app.use(store, appStateKey).use(router).mount('#app')
