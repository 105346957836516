import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { StorageKey } from "@/constant";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        props: ({ query }) => query
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/NonFound.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Check Logined
router.beforeEach((to, from, next) => {
    if ((to.path.startsWith('/home')) && !routerStore.isLogined) {
        let k = StorageKey.Login_RedirectionTaskKeyPrefix + Math.round(Math.random() * 1000000).toString()
        sessionStorage.setItem(k, JSON.stringify(to))
        next({ path: '/login', query: { targetKey: k } })
    } else {
        next()
    }
})

export declare interface RouterStore {
    isLogined: boolean
}

export const routerStore = { isLogined: false } as RouterStore

export default router
